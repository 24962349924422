import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Form, Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import {
  Typography,
  Paper,
  Grid,
  Button,
  CssBaseline,
  MenuItem,
} from '@material-ui/core';

const apikey = 'AIzaSyD4n0eLLbifLpUZhuw5_SaqHLSsN5bECtk';

let price = 65;
let showPrice = false;

const onSubmit = async values => {
  showPrice = true;
  // window.alert(JSON.stringify(values, 0, 2));
};

const validate = values => {
  price = 65;

  if (values.animal === 'cat') {
    price = price + 82.5;
  } else if (values.animal === 'dog_less_10') {
    price = price + 89.6;
  } else if (values.animal === 'dog_10_20') {
    price = price + 111.1;
  } else if (values.animal === 'dog_20_30') {
    price = price + 116.2;
  } else if (values.animal === 'dog_30_40') {
    price = price + 126.8;
  } else if (values.animal === 'dog_40_50') {
    price = price + 133.4;
  } else if (values.animal === 'dog_50_60') {
    price = price + 155;
  }

  if (values.cremate_shipping === 'cat_dog_less_10') {
    price = price + 10;
  } else if (values.cremate_shipping === 'dog_10_20') {
    price = price + 15;
  } else if (values.cremate_shipping === 'dog_over_20') {
    price = price + 30;
  }

  if (values.communal_cremation === 'less_3') {
    price = price + 30;
  } else if (values.communal_cremation === '3_10') {
    price = price + 50;
  } else if (values.communal_cremation === '10_20') {
    price = price + 60;
  } else if (values.communal_cremation === '20_30') {
    price = price + 70;
  } else if (values.communal_cremation === '30_50') {
    price = price + 85;
  }

  if (values.single_cremation === 'less_3') {
    price = price + 75;
  } else if (values.single_cremation === '3_10') {
    price = price + 102;
  } else if (values.single_cremation === '10_20') {
    price = price + 120;
  } else if (values.single_cremation === '20_30') {
    price = price + 137;
  } else if (values.single_cremation === '30_40') {
    price = price + 152;
  } else if (values.single_cremation === '50_50') {
    price = price + 162;
  }
};

function App() {
  const origin = {latitude: 37.3318456, longitude: -122.0296002};
  const destination = {latitude: 37.771707, longitude: -122.4053769};
  const GOOGLE_MAPS_APIKEY = apikey;
  const LATITUDE = 60.242621;
  const LONGITUDE = 24.656072;

  return (
    <div style={{padding: 16, margin: 'auto', maxWidth: 600}}>
      <CssBaseline/>
      <Typography variant="h4" align="center" component="h1" gutterBottom style={{ display: 'none' }}>
        Eutanasialaskuri
      </Typography>
      <Typography variant="h5" align="center" component="h2" gutterBottom style={{ display: 'none' }}>
        Laskurin avulla voit helposti saada arvion eutanasian hinnasta. Vai jotain muuta selitettä tähän?
      </Typography>
      <Form
        onSubmit={onSubmit}
        validateOnBlur={true}
        initialValues={{employed: true, stooge: 'larry'}}
        validate={validate}
        render={({handleSubmit, reset, submitting, pristine, values}) => (
          <form onSubmit={handleSubmit}>
            <Paper style={{padding: 16}}>
              <Typography variant="h5" align="center" component="h5" gutterBottom>
                Matkakulut. Oittaa - oma osoite (syötä alle) - Oittaa. 1€ / km
              </Typography>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    required
                    name="address"
                    component={TextField}
                    type="text"
                    label="Osoite"
                  />
                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="animal"
                    component={Select}
                    label="Valitse eläin"
                    required
                    formControlProps={{fullWidth: true}}
                  >
                    <MenuItem value="cat">Kissa</MenuItem>
                    <MenuItem value="dog_less_10">Koira alle 10kg</MenuItem>
                    <MenuItem value="dog_10_20">Koira 10kg-20kg</MenuItem>
                    <MenuItem value="dog_20_30">Koira 20kg-30kg</MenuItem>
                    <MenuItem value="dog_30_40">Koira 30kg-40kg</MenuItem>
                    <MenuItem value="dog_40_50">Koira 40kg-50kg</MenuItem>
                    <MenuItem value="dog_50_60">Koira 50kg-60kg</MenuItem>
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="cremate_shipping"
                    component={Select}
                    required
                    label="Toimitus tuhkaukseen"
                    formControlProps={{fullWidth: true}}
                  >
                    <MenuItem value="no">Ei</MenuItem>
                    <MenuItem value="cat_dog_less_10">Koira alle 10kg + kissa</MenuItem>
                    <MenuItem value="dog_10_20">Koira 10kg-20kg</MenuItem>
                    <MenuItem value="dog_over_20">Koira yli 20kg</MenuItem>
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="communal_cremation"
                    component={Select}
                    required
                    label="Yhteistuhkaus"
                    formControlProps={{fullWidth: true}}
                  >
                    <MenuItem value="no">Ei tuhkausta</MenuItem>
                    <MenuItem value="less_3">Yhteistuhkaus alle 3kg</MenuItem>
                    <MenuItem value="3_10">Yhteistuhkaus 3kg-10kg</MenuItem>
                    <MenuItem value="10_20">Yhteistuhkaus 10-20kg</MenuItem>
                    <MenuItem value="20_30">Yhteistuhkaus 20-30kg</MenuItem>
                    <MenuItem value="30_50">Yhteistuhkaus 30-50kg</MenuItem>
                  </Field>
                </Grid>

                <hr style={{ width: '100%', backgroundColor: '#ABABAB', marginTop: '20px' }} />

                <Grid item xs={12} style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                  <Typography style={{ marginTop: '20px', width: '90%', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                    Yksilötuhkaus laskutetaan Sateenkaarisillan pieneläinkrematorion toimesta ja siihen lisätään valitun uurnan hinta.
                  </Typography>

                  <Typography style={{ marginTop: '20px', width: '90%', textAlign: 'center' }}>
                    Uurnien hinnat <a href={'https://sateenkaarisillan.fi/uurnat-ja-muistotuotteet/'} target={'_blank'} rel={'noopener noreferrer'}>https://sateenkaarisillan.fi/uurnat-ja-muistotuotteet/</a>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="single_cremation"
                    component={Select}
                    required
                    label="Yksilötuhkaus"
                    formControlProps={{fullWidth: true}}
                  >
                    <MenuItem value="no">Ei yksilötuhkausta</MenuItem>
                    <MenuItem value="less_3">Yksilötuhkaus alle 3kg + valittu uurna</MenuItem>
                    <MenuItem value="3_10">Yksilötuhkaus 3-10kg + valittu uurna</MenuItem>
                    <MenuItem value="10_20">Yksilötuhkaus 10-20kg + valittu uurna</MenuItem>
                    <MenuItem value="20_30">Yksilötuhkaus 20-30kg + valittu uurna</MenuItem>
                    <MenuItem value="30_40">Yksilötuhkaus 30-40kg + valittu uurna</MenuItem>
                    <MenuItem value="40_50">Yksilötuhkaus 40-50kg + valittu uurna</MenuItem>
                  </Field>
                </Grid>

                <Grid item style={{marginTop: 16}}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ display: 'none' }}
                    disabled={submitting}
                  >
                    Lähetä
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <Button type={'submit'} style={{ width: 200, height: 40 }}>Laske hinta-arvio</Button>
              </Grid>
              {showPrice &&
              <Typography variant="h4" align="center" component="h4" gutterBottom>
                Hinta-arvio: {price} €
              </Typography>
              }
            </Paper>
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    </div>
  );
}

ReactDOM.render(<App/>, document.querySelector('#root'));
